"use client";

import Script from "next/script";
import React from "react";
interface DeviceScriptProps {
  isMobile: boolean;
  isBot: boolean;
}
export const DeviceScript = (props: DeviceScriptProps) => {
  const {
    isMobile,
    isBot
  } = props;
  return <Script id="device-data" strategy="afterInteractive" dangerouslySetInnerHTML={{
    __html: `
          window.__DEVICE_DATA__ = {
            isMobile: ${isMobile},
            isBot: ${isBot}
          };
        `
  }} data-sentry-element="Script" data-sentry-component="DeviceScript" data-sentry-source-file="index.tsx" />;
};
export default DeviceScript;