"use client";

import React, { createContext, ReactNode, useContext } from "react";
interface DeviceContextType {
  isMobile: boolean;
  isBot: boolean;
}
const DeviceContext = createContext<DeviceContextType | undefined>(undefined);
export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
};
interface DeviceProviderProps {
  children: ReactNode;
  isMobile: boolean;
  isBot: boolean;
}
export const DeviceProvider: React.FC<DeviceProviderProps> = ({
  children,
  isMobile,
  isBot
}) => {
  return <DeviceContext.Provider value={{
    isMobile,
    isBot
  }} data-sentry-element="unknown" data-sentry-component="DeviceProvider" data-sentry-source-file="DeviceContext.tsx">{children}</DeviceContext.Provider>;
};